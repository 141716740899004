<template>
  <div class="redmou">
    <!-- <div class="banner">
      <img class="pc" src="@/assets/images/banner.png">
      <img class="phone" src="@/assets/images/banner_phone.png">
    </div>
    <div class="services">
      <div class="box">
        <h5>
          <span>Our services</span>
        </h5>
        <div class="con">
          <span>
            <img src="@/assets/images/services_1.png">
            Sweet Rate
          </span>
          <span>
            <img src="@/assets/images/services_2.png">
            Fast Loading
          </span>
          <span>
            <img src="@/assets/images/services_3.png">
            Instant Payment
          </span>
          <span>
            <img src="@/assets/images/services_4.png">
            Top Buyers
          </span>
          <span>
            <img src="@/assets/images/services_5.png">
            Trade Online 24 Hours
          </span>
        </div>
        <img class="icon1" src="../assets/images/icon1.png">
        <img class="icon2" src="../assets/images/icon2.png">
      </div>
    </div> -->

    <div class="gift_card">
      <div class="img">
        <img src="../assets/images/giftCard.png" width="100%">
      </div>
      <div class="txt">
        <h5>
          Gift Cards
          <span></span>
        </h5>
        <p>
          Have any unwanted gift cards or gift certificates?
          <br/>
          We buy your gift cards and give you cash for it!
          <br/>
          We may be able to give you up to 95% of its current value. Our process is simple and quick!
        </p>
      </div>
    </div>
    <!-- <div class="choose">
      <div class="box">
        <h5>
          <span>Why Choose US</span>
        </h5>
        <div class="con">
          <p>
            <img src="@/assets/images/choose_1.png">
            <span>Best rates</span>
          </p>
          <p>
            <img src="@/assets/images/choose_2.png">
            <span>various gift card needed</span>
          </p>
          <p>
            <img src="@/assets/images/choose_3.png">
            <span>Most professional team</span>
          </p>
          <p>
            <img src="@/assets/images/choose_4.png">
            <span>trust is our Trading principle</span>
          </p>
          <p>
            <img src="@/assets/images/choose_5.png">
            <span>always fast payment</span>
          </p>
          <p>
            <img src="@/assets/images/choose_6.png">
            <span>feel no pressure to trade with Us</span>
          </p>
        </div>
      </div>
    </div> -->
    <div class="trade">
      <div class="box">
        <h5>
          <span>How to trade with us</span>
        </h5>
        <div class="con">
          <img class="pc" src="@/assets/images/trade.png">
          <img class="phone" src="@/assets/images/trade_phone.png">
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import Vue from 'vue'
import foot from '@/components/footer'
import countryCode from '../utils/countryCode'
import {Carousel, CarouselItem, Select, Option, Input, Loading, Message} from 'element-ui' 
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Select)
Vue.use(Option) 
Vue.use(Input)
Vue.use(Loading.directive) 
Vue.prototype.$message = Message
export default {
  name: '',
  components:{
    foot,
  },
  data () {
    return {
      whatsappLink: 'https://api.whatsapp.com/send/?phone=447936479723',
      isRender: false,
      isClose: true,
      loading: false,
      isContact: false,
      account: '',
      code: '234',
      countryCode: countryCode,
      from: window.location.href,
    }
  },
  computed:{
    bannerHeight(){
      if(document.body.clientWidth>768){
        return '420px'
      }else{
        return (document.body.clientWidth*0.9 - 40)*390/480+'px'
      }
    }
  },
  methods:{

  },
  created(){

  },
  mounted(){

  },
  watch:{

  }
}
</script>

<style lang="less" scoped>
.redmou{
  .banner{
    cursor: pointer;
    img{
      width: 100%;
    }
  }
  img.pc{
    display: block !important;
  }
  img.phone{
    display: none !important;
  }
  .box{
    width: 80%;
    max-width: 1300px;
    margin: 0 auto;
    position: relative;
  }
  .services{
    padding-bottom: 120px;
    .box{
      position: relative;
      z-index: 2;
    }
    .icon1{
      width: 10%;
      position: absolute;
      top: -12%;
      right: -10%;
      z-index: -1;
    }
    .icon2{
      width: 10%;
      position: absolute;
      bottom: -36%;
      left: -10%;
      z-index: -1;
    }
    h5{
      text-align: center;
      margin: 0;
      margin-bottom: 70px;
      span{
        font-size: 40px;
        font-weight: 600;
        color: #1C59BA;
        position: relative;
      }
      span:before{
        content: '';
        position: absolute;
        bottom: -15px;
        left: 0;
        width: 40px;
        height: 8px;
        background: #2F70FE;
      }
    }
    .con{
      display: flex;
      justify-content: space-between;
      span{
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        color: #333333;
        img{
          display: block;
          width: 110px;
          margin: 0 auto;
          margin-bottom: 25px;
        }
      }
    }
  }
  .product{
    background: #f4fff9;
    padding: 40px 0;
    .box>h5{
      display: flex;
      align-items: center;
      justify-content:center;
      font-size: 40px;
      font-weight: 600;
      color: #1C59BA;
      margin: 0;
      img{
        width: 40px;
        margin-right: 20px;
      }
    }
    .con{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .list{
        width: 43%;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px 0px rgba(46, 64, 143, 0.61);
        border-radius: 20px;
        padding: 30px;
        align-items: flex-start;
        margin-top: 50px;
        .top{
          display: flex;
          margin-bottom: 20px;
          .img{
            width: 43%;
            img{
              display: block;
              width: 100%;
            }
          }
          .txt{
            width: 57%;
            padding-left: 30px;
            h5{
              font-size: 24px;
              font-weight: 400;
              color: #333333;
              margin: 0;
              text-align: left;
              margin-bottom: 10px;
            }
            p{
              font-size: 16px;
              font-weight: 500;
              color: #5686D5;
              margin: 0;
              margin-bottom: 15px;
            }
            span{
              margin-top: 20px;
              display: block;
              width: 70%;
              height: 35px;
              background: linear-gradient(0deg, #43D4FF, #1031A4);
              border-radius: 18px;
              line-height: 35px;
              text-align: center;
              font-size: 15px;
              font-weight: 600;
              color: #FDF9F3;
              cursor: pointer;
            }
          }
        }
        .data{
          h5{
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #e9e9e9;
            span{
              flex: 1;
              font-size: 18px;
              font-family: Arial;
              font-weight: 400;
              color: #1C59BA;
              line-height: 50px;
            }
            span:nth-child(2), span:nth-child(3){
              text-align: center;
            }
          }
          p{
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #e9e9e9;
            span{
              flex: 1;
              font-size: 16px;
              font-family: Arial;
              font-weight: 400;
              color: #333333;
              height: 45px;
              align-items: center;
              display: flex;
              flex-wrap: wrap;
              i{
                width: 100%;
                font-size: 12px;
                font-weight: 400;
                color: #5685D5;
                font-style: normal;
              }
              b{
                font-weight: normal;
                display: flex;
                align-items: center;
                img{
                  width: 24px;
                  margin-right: 4px;
                }
              }
            }
            span:nth-child(2), span:nth-child(3){
              text-align: center;
            }
          }
        }
      }
    }
  }
  .choose{
    background: #f4fff9;
    padding: 60px 0;
    h5{
      text-align: center;
      margin: 0;
      margin-bottom: 70px;
      span{
        font-size: 40px;
        font-weight: 600;
        color: #1C59BA;
        position: relative;
      }
      span:before{
        content: '';
        position: absolute;
        bottom: -15px;
        left: 0;
        width: 40px;
        height: 8px;
        background: #2F70FE;
      }
    }
    .con{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      p{
        width: 48%;
        display: flex;
        align-items: center;
        border-bottom: 1px dashed #7e708d;
        padding: 15px 0;
        img{
          width: 50px;
          margin-right: 50px;
        }
        span{
          font-size: 24px;
          font-weight: 500;
          color: #3E3A39;
        }
      }
    }
  }
  .trade{
    background: #f4fff9;
    padding: 20px 0 80px 0;
    h5{
      text-align: center;
      margin: 0;
      margin-bottom: 70px;
      span{
        font-size: 40px;
        font-weight: 600;
        color: #1C59BA;
        position: relative;
      }
      span:before{
        content: '';
        position: absolute;
        bottom: -15px;
        left: 0;
        width: 40px;
        height: 8px;
        background: #2F70FE;
      }
    }
    .con{
      img{
        width: 100%;
        display: block;
      }
    }
  }
  .feedback{
    background: #f4fff9;
    padding: 40px 0;
    padding-bottom: 120px;
    h5{
      text-align: center;
      margin: 0;
      margin-bottom: 70px;
      span{
        font-size: 40px;
        font-weight: 600;
        color: #1C59BA;
        position: relative;
      }
      span:before{
        content: '';
        position: absolute;
        bottom: -15px;
        left: 0;
        width: 40px;
        height: 8px;
        background: #2F70FE;
      }
    }
    .con{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left{
        width: 40%;
        img{
          width: 100%;
          display: block;
        }
      }
      .right{
        width: 49%;
        background: #F8F8F8;
        p{
          font-size: 20px;
          font-weight: 400;
          color: #343333;
          text-align: center;
          padding: 20px 0;
        }
        .list{
          padding: 0px 40px 20px 40px;
        }
        .btn{
          background: #1C59BA;
          width: 100%;
          line-height: 40px;
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
  .foot{
    width: 100%;
    height: 90px;
    position: fixed;
    bottom: 0;
    left: 0;
    background: rgba(51,51,51,0.5);
    z-index: 9;
    .box{
      position: relative;
      .con{
        >p{
          display: inline-block;
          font-size: 13px;
          font-weight: 600;
          color: #FFFFFF;
          width: 200px;
          text-align: center;
          background: linear-gradient(90deg, #1C59BA, #5C9BFF);
        }
        >p:nth-child(1){
          height: 36px;
          line-height: 36px;
          margin-bottom: 10px;
          margin-top: 10px;
        }
        >p:nth-child(3){
          height: 25px;
          line-height: 25px;
        }
        .ewm{
          position: absolute;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: flex-end;
          span{
            font-size: 12px;
            font-weight: 600;
            color: #FFFFFF;
            -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
            cursor: pointer;
          }
          img{
            width: 110px;
          }
        }
        .close{
          position: absolute;
          right: -5%;
          top: 50%;
          img{
            width: 26px;
            margin-top: -13px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .gift_card{
    background-color: #fff;
    padding: 80px 6% 80px 12%;
    display: flex;
    align-items: center;
    .img{
      width: 45%;
      margin-right: 5%;
    }
    .txt{
      width: 55%;
      background: #E8F8FF;
      border-radius: 16px;
      padding: 30px;
      h5{
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 5%;
        span{
          display: block;
          width: 100px;
          height: 5px;
          background: #348CFF;
          margin-top: 5px;
        }
      }
      p{
        font-size: 16px;
        font-weight: 400;
        color: #757575;
        line-height: 36px;
      }
      i{
        margin-top: 5%;
        display: block;
        cursor: pointer;
        width: 180px;
        height: 45px;
        background: linear-gradient(90deg, #43D4FF, #1031A4);
        border-radius: 18px;
        font-size: 20px;
        line-height: 45px;
        font-style: normal;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        color: #FDF9F3;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 30px;
          margin-left: 12px;
        }
      }
    }
  }
  .contact_box{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    .bg{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.3);
      z-index: -1;
    }
    .con{
      width: 80%;
      max-width: 600px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      background-color: #fff;
      border-radius: 10px;
      padding: 40px 20px;
      >h5{
        font-size: 20px;
        color: #212121;
        margin-bottom: 24px;
        text-align: center;
      }
      >span{
        position: absolute;
        top: 0;
        right: -50px;
        cursor: pointer;
        img{
          width: 30px;
        }
      }
      .list{
        display: flex;
        align-items: center;
        >span{
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #212121;
          margin-right: 5px;
          i{
            color: red;
            font-size: 20px;
            margin-right: 5px;
          }
        }
        .el-select{
          width: 50%;
        }
      }
      .btn{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        a{
          margin: 0 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          color: #515151;
          border-radius: 4px;
          border: 1px solid #999;
          line-height: 2.4;
          width: 120px;
          cursor: pointer;
          -webkit-transition: all cubic-bezier(.18,1,.21,1) .9s;	
          transition: all cubic-bezier(.18,1,.21,1) .9s;
          img{
            width: 20px;
            display: block;
            margin-left: 10px;
          }
        }
        a:hover{
          color: #1C59BA;
          border: 1px solid #1C59BA; 
        }
        span{
          margin: 0 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          color: #fff;
          background-color: #1C59BA;
          border-radius: 4px;
          border: 1px solid #1C59BA;
          line-height: 2.4;
          width: 120px;
          cursor: pointer;
        }
      }
    }
  }
}
@media (max-width: 768px){
  .redmou{
    .box{
      width: 90%;
    }
    img.pc{
      display: none !important;
    }
    img.phone{
      display: block !important;
    }
    .services{
      padding-bottom: 20px;
      .icon1{
        display: none;
      }
      .icon2{
        display: none;
      }
      h5{
        margin-bottom: 40px;
        span{
          font-size: 24px;
        }
        span:before{
          bottom: -10px;
          width: 30px;
          height: 5px;
        }
      }
      .con{
        flex-wrap: wrap;
        justify-content: flex-start;
        span{
          width: 33%;
          font-size: 12px;
          margin-bottom: 20px;
          img{
            width: 50px;
            margin-bottom: 8px;
          }
        }
      }
    }
    .product{
      padding: 30px 0;
      .box>h5{
        font-size: 24px;
        img{
          width: 20px;
          margin-right: 10px;
        }
      }
      .con{
        .list{
          width: 100%;
          margin-top: 25px;
          padding: 15px;
          .top{
            margin-bottom: 10px;
            .img{
              width: 40%;
            }
            .txt{
              width: 60%;
              padding-left: 10px;
              h5{
                font-size: 16px;
                margin-bottom: 5px;
              }
              p{
                font-size: 12px;
                margin-bottom: 5px;
              }
              span{
                width: 50%;
                font-size: 12px;
                height: 24px;
                line-height: 24px;
                border-radius: 12px;
              }
            }
          }
          .data{
            h5{
              span{
                font-size: 14px;
                line-height: 40px;
              }
            }
            p{
              span{
                font-size: 12px;
                height: 40px;
              }
            }
          }
        }
      }
    }
    .choose{
      padding: 40px 0 20px 0;
      h5{
        margin-bottom: 30px;
        span{
          font-size: 24px;
        }
        span:before{
          bottom: -10px;
          width: 30px;
          height: 5px;
        }
      }
      .con{
        p{
          width: 100%;
          padding: 10px 0;
          img{
            width: 30px;
            margin-right: 20px;
          }
          span{
            font-size: 16px;
          }
        }
      }
    }
    .trade{
      padding: 20px 0 40px 0;
      h5{
        margin-bottom: 30px;
        span{
          font-size: 24px;
        }
        span:before{
          bottom: -10px;
          width: 30px;
          height: 5px;
        }
      }
    }
    .feedback{
      padding: 20px 0;
      padding-bottom: 120px;
      h5{
        margin-bottom: 30px;
        span{
          font-size: 24px;
        }
        span:before{
          bottom: -10px;
          width: 30px;
          height: 5px;
        }
      }
      .con{
        flex-wrap: wrap;
        .left{
          width: 100%;
          display: none;
        }
        .right{
          width: 100%;
          margin-top: 30px;
          p{
            font-size: 16px;
            padding: 15px 0;
          }
          .list{
            padding: 0px 20px 20px 20px;
          }
        }
      }
    }
    .foot{
      height: 70px;
      .box{
        .con{
          >p{
            width: 180px;
            font-size: 12px;
          }
          >p:nth-child(1){
            height: 25px;
            line-height: 25px;
            margin-bottom: 6px;
            margin-top: 6px;
          }
          >p:nth-child(3){
            height: 25px;
            line-height: 25px;
          }
          .ewm{
            right: 40px;
            span{
              display: none;
            }
            img{
              width: 90px;
            }
          }
          .close{
            right: 0%;
            img{
              width: 20px;
            }
          }
        }
      }
    }
    .gift_card{
      flex-wrap: wrap;
      padding: 30px 6% 30px 6%;
      .img{
        width: 70%;
        margin-left: 15%;
        margin-right: 0;
        margin-bottom: 2%;
      }
      .txt{
        width: 100%;
        padding: 20px;
        h5{
          font-size: 20px;
          span{
            width: 80px;
            height: 4px;
          }
        }
        p{
          font-size: 14px;
          line-height: 24px;
        }
        i{
          width: 150px;
          height: 40px;
          cursor: pointer;
          line-height: 40px;
          font-size: 16px;
          font-style: normal;
          border-radius: 12px;
          img{
            width: 20px;
          }
        }
      }
    }
    .contact_box{
      .con{
        >span{
          top: -50px;
          right: 0;
        }
        .list{
          flex-wrap: wrap;
          >span{
            margin-bottom: 10px;
          }
          .el-select{
            width: 100%;
            margin-bottom: 15px;
          }
        } 
      }
    }
  }
}
</style>

<style lang="less">
.redmou{
  .feedback{
    .el-carousel__item {
      img{
        display: block;
        width: 100%;
      }
    }
  }
}
</style>
